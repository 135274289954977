// ** Initial State
const initialState = {
  data: {},
  params: {},
  dashloading: false
}

const dashboardData = (state = initialState, action) => {
  switch (action.type) {
    case 'DASHBOARD_GET_DATA':
      return {
        ...state,
        data: action.data,
        params: action.params
      }
    case 'clear_dashboard_data':
      return {
        ...state,
        data: action.data
      }
    case 'DASH_LOADING':
      return {
        ...state,
        dashloading: action.dashloading
      }
    default:
      return { ...state }
  }
}

export { dashboardData }