// ** Initial State
const initialState = {
    data: [],
    total: 1,
    params: {}
  }
  
  const customerInformation = (state = initialState, action) => {
    switch (action.type) {
      case 'CUSTOMER_INFORMATION_GET_DATA':
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
      case 'CUSTOMER_INFORMATION_GET_USER':
        return { ...state, selectedUser: action.selectedUser }
      case 'CUSTOMER_INFORMATION_ADD_USER':
        return { ...state }
      case 'CUSTOMER_INFORMATION_DELETE_USER':
        return { ...state }
      default:
        return { ...state }
    }
  }


  export {customerInformation}
  