// ** Initial State
const initialState = {
    data: []
  }
  
  const promotion = (state = initialState, action) => {
    switch (action.type) {
      case 'PROMOTION_LIST':
        return {
          ...state,
          data: action.data
        }
      default:
        return { ...state }
    }
  }

  export default promotion