// ** Initial State
const initialState = {
  data: [],
  round: [],
  dataId: [],
  total: 1,
  params: {},
  modal: {
    id: -1,
    title: '',
    customer: '',
    phone: '',
    deposit: 0,
    promotionName: '',
    promotionType: '',
    bonusType: '',
    timeCreate: '',
    type: '',
    promotionStatus: -1,
    status: false
  },
  content: {
    id: -1,
    title: '',
    contentSubject: '',
    contentText: '',
    contentPicture: '',
    description: '',
    status: false
  },
  code: {
    data: {},
    title: '',
    status: false
  },
  statusPromotion: -1,
  img: {},
  open_modal: false
}

const promotionApplyList = (state = initialState, action) => {
  switch (action.type) {
    case 'PROMOTION_APPLY_LIST_GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    default:
      return { ...state }
  }
}

const promotionCodeID = (state = initialState, action) => {
  switch (action.type) {
    case 'PROMOTION_CODE_ID_GET_DATA':
      return {
        ...state,
        dataId: action.data
      }
    default:
      return { ...state }
  }
}

const promotionCodeList = (state = initialState, action) => {
  switch (action.type) {
    case 'PROMOTION_CODE_LIST_GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    default:
      return { ...state }
  }
}

const promotionContentList = (state = initialState, action) => {
  switch (action.type) {
    case 'PROMOTION_CONTENT_LIST_GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    default:
      return { ...state }
  }
}
const promotionStatisticList = (state = initialState, action) => {
  switch (action.type) {
    case 'PROMOTION_CONTENT_LIST_GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    default:
      return { ...state }
  }
}

const modalPromotionInformation = (state = initialState, action) => {
  switch (action.type) {
    case 'MODAL_PROMOTION_INFORMATION_DATA':
      return {
        ...state,
        modal: action.data
      }
    default:
      return { ...state }
  }
}

const statusPromotionInformation = (state = initialState, action) => {
  switch (action.type) {
    case 'STATUS_PROMOTION_INFORMATION_DATA':
      return {
        ...state,
        statusPromotion: action.data
      }
    default:
      return { ...state }
  }
}

const modalContentInformation = (state = initialState, action) => {
  switch (action.type) {
    case 'MODAL_CONTENT_INFORMATION_DATA':
      return {
        ...state,
        content: action.data
      }
    default:
      return { ...state }
  }
}

const modalCodeInformation = (state = initialState, action) => {
  switch (action.type) {
    case 'MODAL_CODE_INFORMATION_DATA':
      return {
        ...state,
        code: action.data
      }
    default:
      return { ...state }
  }
}

const modalCycleAdd = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_DATA_CYCLE':
      return {
        ...state,
        round: action.data
      }
    default:
      return { ...state }
  }
}

const modalGetImg = (state = initialState, action) => {
  switch (action.type) {
    case 'PROMOTION_CODE_DATA_IMG':
      return {
        ...state,
        img: action.data
      }
    default:
      return { ...state }
  }
}

const modalOpen = (state = initialState, action) => {
  switch (action.type) {
    case 'PROMOTION_CODE_OPEN_MODAL':
      return {
        ...state,
        open_modal: action.data
      }
    default:
      return { ...state }
  }
}

export { promotionCodeID, promotionApplyList, promotionCodeList, promotionContentList, promotionStatisticList, modalPromotionInformation, statusPromotionInformation, modalContentInformation, modalCodeInformation, modalCycleAdd, modalGetImg, modalOpen }
