// ** Initial State
const initialState = {
    data: [],
    bank: []
  }
  
  const campGame = (state = initialState, action) => {
    switch (action.type) {
      case 'CAMP_GAME_LIST':
        return {
          ...state,
          data: action.data
        }
      case 'CAMP_GAME_LIST':
        return {
          ...state,
          bank: action.data
        }
      default:
        return { ...state }
    }
  }
  
  export default campGame