// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import snackbar from './snackbar'
import buttonsound from './buttonsound'
import sound from './sound'
import customer from './customer'
import agent from './agent'
import promotion from './promotion'
import campGame from './camp-game'
import rolePermission from './role'
import lottery from './lottery'
import credit from './credit'

//** APPS 
import datatables from '@views/apps/datatable/store/reducer'
import { modalCustomer, gameTransaction, promotionInfo, inviteFriendInfo, creditTransaction, modalCustomerEditBank, modalCustomerDeleteBank, walletList, shopList, activityList, customerBalance, customerSummary } from '@views/apps/modal-customer/store/reducer'
import { promotionShow } from '@views/apps/modal-promotion/store/reducer'
import { picturePath } from '@views/apps/modal-picture/store/reducer'
// ** Sub Reducers
import { customerInformation } from '@src/views/pages/customer/store/reducer/information'
// import { withdrawInformation, customerListInformation, bankAgentListInformation, modalWithdrawInformation } from '@src/views/pages/transfer/store/reducer/information'
import { paymentBanks, agentBankListDeposit, promotionListDeposit, withdrawInformation, customerListInformation, bankAgentListInformation, modalWithdrawInformation, modalDepositInformation, searchUsernameData, paymentCheckDeposit, matchBankGetData} from '@src/views/pages/payment/store/reducer/payment'
import { promotionApplyList, promotionCodeList, promotionContentList, promotionStatisticList, modalPromotionInformation, statusPromotionInformation, modalContentInformation, modalCodeInformation, promotionCodeID,  modalCycleAdd, modalGetImg, modalOpen } from '@src/views/pages/promotion/store/reducer/promotion'
import { organization, employeeList } from '@src/views/pages/organization/store/reducer/organization'
import { setting } from '@src/views/pages/system-setting/store/reducer/setting'
import { CustomerInfo } from '@src/views/apps/user/store/reducer'
import paymentHistory from '@src/views/apps/user/store/reducer/payment-history'
import { report } from '@src/views/pages/report/store/reducer/report'
import { loginInformation, promotionInformation, inviteFriendInformation, bankInformation } from '@src/views/apps/user/store/reducer/history-report'
import { dashboardData } from '@src/views/dashboard/store/reducer/dashboard'
import { transactionWait } from './../../@core/layouts/components/footer/store/reducer/main'
import { marketing } from '../../views/pages/marketing/store/reducer'

const rootReducer = combineReducers({
  // ** Apps
  datatables,
  modalCustomer,
  promotion,
  auth,
  navbar,
  layout,
  snackbar,
  buttonsound,
  customer,
  agent,
  campGame,
  sound,
  lottery,
  customerInformation,
  organization,
  employeeList,
  paymentBanks,
  promotionCodeID,
  promotionApplyList,
  promotionCodeList,
  promotionContentList,
  promotionStatisticList,
  agentBankListDeposit,
  promotionListDeposit,
  withdrawInformation,
  customerSummary,
  customerListInformation,
  bankAgentListInformation,
  modalWithdrawInformation,
  modalDepositInformation,
  modalPromotionInformation,
  statusPromotionInformation,
  modalContentInformation,
  setting,
  CustomerInfo,
  paymentHistory,
  modalCodeInformation,
  report,
  marketing,
  // UserReducer,
  modalCodeInformation,
  dashboardData,
  loginInformation,
  promotionInformation,
  inviteFriendInformation,
  bankInformation,
  gameTransaction,
  promotionInfo,
  inviteFriendInfo,
  searchUsernameData,
  creditTransaction,
  modalCustomerEditBank,
  modalCustomerDeleteBank,
  transactionWait,
  matchBankGetData,
  walletList,
  shopList,
  activityList,
  paymentCheckDeposit,
  promotionShow,
  picturePath,
  customerBalance,
  modalGetImg,
  modalCycleAdd,
  modalOpen,
  // promotionApply,
  rolePermission,
  credit
})

export default rootReducer
