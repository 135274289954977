const initialState = {
    picture: {
            data: [],
            total: 0
    }
}
export const picturePath = (state = initialState, action) => {
    switch (action.type) {
      case 'PICTURE_PATH_DATA':
        return {
          ...state,
          picture: action.data
        }
      default:
        return { ...state }
    }
  }