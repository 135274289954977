// ** Initial State
const initialState = {
    data: [],
    total: 1,
    params: {}
  }

const loginInformation = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN_GET_DATA':
            return {
                ...state,
                data: action.data,
                total: action.totalPages,
                params: action.params
            }
        default:
            return { ...state }
    }
}

const promotionInformation = (state = initialState, action) => {
    switch (action.type) {
        case 'PROMOTION_GET_DATA':
            return {
                ...state,
                data: action.data,
                total: action.totalPages,
                params: action.params
            }
        default:
            return { ...state }
    }
}

const inviteFriendInformation = (state = initialState, action) => {
    switch (action.type) {
        case 'INVITE_FRIEND_GET_DATA':
            return {
                ...state,
                data: action.data,
                total: action.totalPages,
                params: action.params
            }
        default:
            return { ...state }
    }
}

const bankInformation = (state = initialState, action) => {
    switch (action.type) {
        case 'BANK_GET_DATA':
            return {
                ...state,
                data: action.data,
                total: action.totalPages,
                params: action.params
            }
        default:
            return { ...state }
    }
}

export { loginInformation, promotionInformation, inviteFriendInformation, bankInformation }