const initialState = {
  promotiondeposit: {
    data: [],
    total: 0
  },
  promotionwithdraw: {
    data: [],
    total: 0
  }
}
export const promotionShow = (state = initialState, action) => {
  switch (action.type) {
    case 'PROMOTION_DEPOSIT_DATA':
      return {
        ...state,
        promotiondeposit: action.data
      }
    case 'PROMOTION_WITHDRAW_DATA':
      return {
        ...state,
        promotionwithdraw: action.data
      }
    default:
      return { ...state }
  }
}